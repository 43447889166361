<script setup>
import { ref } from 'vue'
import logo from '../images/logo.png'

const navigationItems = ref([
  { label: 'Home', link: '#hero' },
  { label: 'About', link: '#about' },
  { label: 'Contact', link: '#contact' },
])

const isMenuOpen = ref(false)

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value
}
</script>

<template>
  <header class="header">
    <div class="header__content">
      <div class="header__logo-container" href="#hero">
        <div class="header__logo-img-cont">
          <img class="header__logo-img" alt="lbb-logo" :src="logo" />
        </div>
        <div class="header__logo-brand">
          <div class="header__logo-brand top">Little Big</div>
          <div class="header__logo-brand bottom">Bro</div>
        </div>
      </div>
      <div class="header__main">
        <ul class="header__links">
          <li
            class="header__link-wrapper"
            v-for="(item, index) in navigationItems"
            :key="index"
          >
            <a class="header__link" :href="item.link">{{ item.label }}</a>
          </li>
        </ul>
        <div class="header__sm-buttons">
          <img
            src="https://d33wubrfki0l68.cloudfront.net/79e75114856ae61628d2ad26504e3ff4ab2c71b6/f06a4/assets/svg/ham-menu.svg"
            alt="hamburger menu"
            class="header__main-ham-menu"
            :class="isMenuOpen ? 'd-none' : ''"
            @click="toggleMenu"
          />
          <img
            src="https://d33wubrfki0l68.cloudfront.net/de2a681c8ca1630b29949b3a34bf158a686a0554/6a0ec/assets/svg/ham-menu-close.svg"
            alt="hamburger menu close"
            class="header__main-ham-menu-close"
            :class="!isMenuOpen ? 'd-none' : ''"
            @click="toggleMenu"
          />
        </div>
      </div>
    </div>
    <div
      class="header__sm-menu"
      :class="isMenuOpen ? 'header__sm-menu--active' : ''"
    >
      <div class="header__sm-menu-content">
        <ul class="header__sm-menu-links">
          <li
            class="header__sm-menu-link"
            v-for="(item, index) in navigationItems"
            :key="index"
            @click="toggleMenu"
          >
            <a :href="item.link">{{ item.label }}</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<style>
.header {
  width: 100%;
  height: 110px;
  z-index: 1000;
  position: fixed;
  background: white;
  color: black;
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}
.header__logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
}
.header__logo-container:hover {
  color: #ffce4e;
}
.header__logo-img-cont {
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  margin-right: 1.5rem;
}
.header__logo-img {
  width: 100%;
  height: 100%;
  display: block;
}
.header__logo-brand {
  font-family: 'Three Point';
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
}
.header__logo-brand .top {
  font-size: 1.2rem;
}
.header__logo-brand .bottom {
  letter-spacing: 1px;
  font-size: 3.5rem;
}
.header__links {
  display: flex;
  margin: 0;
  padding: 0;
}
.header__link {
  font-size: 1.4rem;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.header__link {
  padding: 1rem 2rem;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Three Point';
  font-weight: 300;
  color: black;
  transition: color 0.3s;
}
.header__link:hover {
  color: #ffce4e;
}
.header__sm-buttons {
  display: none;
}
.d-none {
  display: none;
}
.header__sm-menu {
  background: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.header__sm-menu--active {
  visibility: hidden;
  opacity: 0;
}
.header__sm-menu-links {
  padding: 0;
  margin: 0;
}
.header__sm-menu-link a {
  display: block;
  text-decoration: none;
  padding: 1.5rem 3rem;
  font-size: 1rem;
  color: black;
  text-align: right;
  border-bottom: 1px solid #eee;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: color 0.3s;
  font-family: 'Three Point';
}
.header__sm-menu-link:first-child a {
  border-top: 1px solid #eee;
}
@media only screen and (max-width: 1000px) {
  .header__sm-buttons {
    display: block;
  }
  .header__sm-menu--active {
    visibility: visible;
    opacity: 1;
  }
  .header__links {
    display: none;
  }
  .header__content {
    padding: 0.5em 1.2em;
  }
  .header__logo-img-cont {
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 1.2rem;
  }
  .header__logo-span {
    font-size: 1rem;
  }
}
</style>
