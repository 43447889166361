<script setup>
import img from "../images/contact.png";
import emailjs from "@emailjs/browser";
import VueHoneypot from "vue-honeypot";
</script>

<script>
export default {
  components: {
    VueHoneypot,
  },

  data() {
    return {
      name: "",
      email: "",
      message: "",
      /*Honeypot field*/
      firstname: "",
    };
  },
  methods: {
    onReset(event) {
      // Reset the form fields
      this.name = "";
      this.email = "";
      this.message = "";
      /*Honeypot field*/
      this.firstname = "";
    },
    sendEmail() {
      if (this.firstname !== "") {
        // Honeypot
        console.log("Bot detected. Form not submitted!");
        return;
      }

      if (this.name == "" || this.email == "" || this.message == "") {
        alert("All form fields must be completed!");
        return;
      }

      emailjs
        .sendForm(
          process.env.VUE_APP_EMAIL_SERVICE_ID,
          process.env.VUE_APP_EMAIL_SERVICE_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_EMAIL_SERVICE_PUB_KEY
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            alert("The form has been sent successfully!");
            this.name = "";
            this.email = "";
            this.message = "";
            /*Honeypot field*/
            this.firstname = "";
          },
          (error) => {
            console.log("FAILED...", error.text);
            alert("ERROR: The form has not been sent!");
          }
        );
    },
  },
};
</script>

<template>
  <div class="spacer dark__spacer"></div>
  <div class="spacer" id="contact"></div>
  <section class="contact__section">
    <div class="contact__container">
      <form ref="form" @submit.prevent="sendEmail">
        <div class="contact__header">
          <div class="contact__header-img-cont">
            <img class="contact__header-img" :src="img" alt="contact logo" />
          </div>
          <div class="contact__header-title">Contactez-nous</div>
        </div>
        <div class="contact__fields">
          <div class="contact__field-cont">
            <div class="contact__field-label">
              <label for="name">Name</label>
            </div>
            <div class="contact__field-input">
              <input
                type="text"
                class="custom-input"
                v-model="name"
                id="name"
                placeholder="Your name"
                name="name"
              />
            </div>
          </div>
          <div class="contact__field-cont">
            <div class="contact__field-label">
              <label for="email">Email</label>
            </div>
            <div class="contact__field-input">
              <input
                type="email"
                class="custom-input"
                v-model="email"
                id="email"
                placeholder="Your email"
                name="email"
              />
            </div>
          </div>
          <div class="contact__field-cont">
            <div class="contact__field-label">
              <label for="message">Message</label>
            </div>
            <div class="contact__field-input">
              <textarea
                class="custom-input"
                v-model="message"
                id="message"
                placeholder="Your message"
                name="message"
              ></textarea>
            </div>
          </div>
          <div class="contact__field-cont" style="display: none">
            <div class="contact__field-label">
              <label for="firstname">firstname</label>
            </div>
            <div class="contact__field-input">
              <!-- Honeypot field -->
              <input
                type="text"
                class="custom-input"
                v-model="firstname"
                id="firstname"
                name="firstname"
              />
            </div>
          </div>
        </div>
        <div class="contact__buttons">
          <button type="reset" @click="onReset" class="btn">Reset</button>
          <button type="submit" class="btn">Send</button>
        </div>
      </form>
    </div>
  </section>
  <div class="spacer" id="contact"></div>
</template>

<style>
.contact__section {
  background: white;
  height: calc(100vh - 110px);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact__container {
  width: 60%;
  padding: 3rem 3rem 3rem 2rem;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #828080;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}
.contact__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.contact__header-img-cont {
  height: 50px;
}
.contact__header-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contact__header-title {
  font-size: calc(1.2vh + 1.2vw);
  font-family: "Three Point";
}
.contact__field-cont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}
.contact__field-input {
  width: 80%;
}
label {
  font-size: calc(1vh + 1vw);
  padding: 0.5rem;
}
input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
  font-size: calc(0.9vh + 0.9vw);
  font-family: "Roboto";
}
textarea {
  width: 100%;
  height: 100px;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
  font-size: calc(0.9vh + 0.9vw);
  font-family: "Roboto";
}
.contact__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  align-items: center;
}
button {
  background: black;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  font-size: calc(1vw + 1vh);
  box-shadow: 2px 2px 4px #888888;
}
@media only screen and (max-width: 1000px) {
  .contact__field-cont {
    flex-direction: column;
  }
  .contact__field-label {
    margin-bottom: 0.5rem;
  }
  .contact__container {
    width: 80%;
    padding: 1rem;
  }
  .contact__field-input {
    width: 90%;
  }
}
</style>
