<script setup>
import Header from './components/Header.vue'
import HeroSection from './components/HeroSection.vue'
import TeamSection from './components/TeamSection.vue'
import ContactSection from './components/ContactSection.vue'
import Footer from './components/Footer.vue'
import { useTitle } from '@vueuse/core'

const title = useTitle('Little Big Bro')
</script>

<template>
  <div class="content">
    <Header />
    <HeroSection />
    <TeamSection />
    <ContactSection />
    <Footer />
  </div>
</template>

<style>
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@font-face {
  font-family: 'Three Point';
  src: local('Three Point'), url(./fonts/ThreePoint.otf) format('truetype');
}
.spacer {
  height: 110px;
  width: 100%;
}
.dark__spacer {
  background: #f8f8f8;
}
</style>
